import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Link } from "react-router-dom";

import "../css/ShareholdersMeetings.css";

const aws_region = process.env.REACT_APP_AWS_REGION;

const cred = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const s3Client = new S3Client({
  region: aws_region,
  credentials: cred,
});

function ShareholdersMeetings() {
  const { t } = useTranslation();

  const [jsonObject, setJsonObject] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const [assembleaOttobre2023, setassembleaOttobre2023] = useState("");
  const [assembleaDicembre2023, setassembleaDicembre2023] = useState("");
  const [assembleaAprile2024, setassembleaassembleaAprile2024] = useState("");
  const [separateFinancialAudited2023, setSeparateFinancialAudited2023] =
    useState("");
  const [boardOfAuditorsBudget2023, setBoardOfAuditorsBudget2023] =
    useState("");
  const [assembleaAprileEN2024, setassembleaassembleaAprileEN2024] =
    useState("");

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);

    const filteredItems = jsonObject.filter((pdf) =>
      pdf.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  };

  const bucket_name = process.env.REACT_APP_S3_BUCKET_NAME;

  const getSignedUrlForFile = async (key) => {
    const command = new GetObjectCommand({
      Bucket: bucket_name,
      Key: key,
    });

    try {
      const signedUrl = await getSignedUrl(s3Client, command);
      return signedUrl;
    } catch (error) {
      console.error("Error generating signed URL:", error);
      return null;
    }
  };

  async function downlaodFiles() {
    setassembleaOttobre2023(
      await getSignedUrlForFile("assemblee_soci/2023/20231005_117.pdf")
    );
    setassembleaDicembre2023(
      await getSignedUrlForFile(
        "assemblee_soci/2023/Convocazione-Assemblea-soci-dicembre-2023.pdf"
      )
    );
    setassembleaassembleaAprile2024(
      await getSignedUrlForFile(
        "assemblee_soci/2024/assemblea_ordinaria_soci_29_Aprile_2024.docx"
      )
    );
    setSeparateFinancialAudited2023(
      await getSignedUrlForFile(
        "financial_reports/2023/bilancio_separato_rigsavespa_revisionato_31_12_2023.pdf"
      )
    );
    setBoardOfAuditorsBudget2023(
      await getSignedUrlForFile(
        "assemblee_soci/2024/relazione _collegio_sindacale_bil_2023_rigsavespa.pdf"
      )
    );
    setassembleaassembleaAprileEN2024(
      await getSignedUrlForFile(
        "assemblee_soci/2024/shareholdes_meeting_29_April_2024.docx"
      )
    );
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]).then((responses) => {
      const tempArrayResults = [
        {
          date: `24 ${t("main.investorSection.october")} 2023`,
          name: `${t("main.investorSection.callShareholdersShares")} - IT`,
          url: assembleaOttobre2023,
        },
        {
          date: `13 ${t("main.investorSection.december")} 2023`,
          name: `${t("main.investorSection.callShareholdersShares")} - EN`,
          url: assembleaDicembre2023,
        },
        {
          date: `29 ${t("main.investorSection.april")} 2024`,
          name: `${t("main.investorSection.callShareholdersShares")} - IT`,
          url: assembleaAprile2024,
        },
        {
          date: "",
          name: t("main.investorSection.separateFinancialStatements"),
          url: separateFinancialAudited2023,
        },
        {
          date: "",
          name: t("main.investorSection.reportBoard"),
          url: boardOfAuditorsBudget2023,
        },
        {
          date: `29 ${t("main.investorSection.april")} 2024`,
          name: `${t("main.investorSection.callShareholdersShares")} - EN`,
          url: assembleaAprileEN2024,
        },
      ];
      setFilteredData(tempArrayResults);
      setJsonObject(tempArrayResults);
    });
  }, [
    assembleaAprile2024,
    assembleaAprileEN2024,
    assembleaDicembre2023,
    assembleaOttobre2023,
    boardOfAuditorsBudget2023,
    downlaodFiles,
    separateFinancialAudited2023,
    t,
  ]);
  return (
    <>
      <div className="container wrapper-documents pt-5 mt-5 mb-5 pb-5">
        <div className="internal-wrap pt-5 mt-5 pb-5 mb-5">
          <div className="rigsave-custom-title text-center pb-5">
            {t("main.shareholdersMeetings.title")}
          </div>
          <div className="text-center pb-5 mb-4">
            <input
              type="text"
              className="input-search"
              onChange={handleInputChange}
              value={searchValue}
            />
          </div>
          {filteredData ? (
            <div className="results-wrapper">
              {filteredData.map((pdf, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-6 card-documents px-5 pt-4 pb-4 mx-5 mb-5"
                  >
                    <div className="title-documents">{pdf.name}</div>
                    <div className="date-documents">{pdf.date}</div>
                    <Link
                      to={pdf.url}
                      className="button-contact final-a list-inline d-block text-center"
                      target="_blank"
                    >
                      Report
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="container justify-content-center text-center">
              <RotatingLines
                strokeColor="#6b97cf"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ShareholdersMeetings;
