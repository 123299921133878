import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import icon_39 from "../assets/icons/icon_39.png";
import icon_2 from "../assets/icons/icon_2.png";

import "../css/Services.css";

function Services() {
  const { t } = useTranslation();

  return (
    <div className="services mt-5 mb-5 pb-5">
      <div className="container pt-5 mt-5">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.services.title")}
        </div>
        <div className="group-detail d-lg-flex justify-content-center pt-5 mt-5 mb-5 pb-5">
          <div className="border-container col-lg-5 mx-5">
            <div className="title">
              {t("main.services.strutturazioneVeicoli")}
            </div>
            <div className="font-aboutUs pt-2">
              {t("main.services.strutturazioneVeicoliText")}
            </div>
          </div>
          <div className="border-container col-lg-5 mx-5">
            <div className="title">{t("main.services.gestPatrimoniale")}</div>
            <div className="font-aboutUs pt-2">
              {t("main.services.gestPatrimonialeText")}
            </div>
          </div>
        </div>
        <div className="values-container pt-5">
          <div className="rigsave-custom-title text-center pt-5 pb-5 mb-4">
            {t("main.services.whyChoseRigsave")}
          </div>
          <div className="d-lg-flex row justify-content-center pb-5">
            <div className="col-lg-6 pe-5 pb-5">
              <img src={icon_39} alt="Inclusion" />
              <div className="title">{t("main.aboutUs.independeceTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.independeceText")}
              </div>
            </div>
            <div className="col-lg-6 px-5 pb-5">
              <img src={icon_2} alt="Transparency" />
              <div className="title">{t("main.aboutUs.transparencyTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.transparencyText")}
              </div>
            </div>
          </div>
          <div className="d-flex button-wrapper justify-content-center pt-2 mt-4">
            <Link
              to={"/contact-us"}
              className="button-contact link-document list-inline d-block text-center"
            >
              {t("main.aboutUs.contactUs")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
