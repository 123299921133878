import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Link } from "react-router-dom";

import "../css/FinancialReports.css";

const aws_region = process.env.REACT_APP_AWS_REGION;

const cred = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const s3Client = new S3Client({
  region: aws_region,
  credentials: cred,
});

function FinancialCalendar() {
  const { t } = useTranslation();

  const [jsonObject, setJsonObject] = useState([]);

  const [financialCalendar2024, setFinancialCalendar2024] = useState("");
  const [financialCalendar2025, setFinancialCalendar2025] = useState("");

  const bucket_name = process.env.REACT_APP_S3_BUCKET_NAME;

  const getSignedUrlForFile = async (key) => {
    const command = new GetObjectCommand({
      Bucket: bucket_name,
      Key: key,
    });

    try {
      const signedUrl = await getSignedUrl(s3Client, command);
      return signedUrl;
    } catch (error) {
      console.error("Error generating signed URL:", error);
      return null;
    }
  };

  async function downlaodFiles() {
    setFinancialCalendar2024(
      await getSignedUrlForFile(
        "financial_calendar/2024/Financial_Calendar-RIGSAVE-SPA.pdf"
      )
    );
    setFinancialCalendar2025(
      await getSignedUrlForFile(
        "financial_calendar/2025/financial_calendar_rigsavespa_2025.pdf"
      )
    );
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]).then((responses) => {
      const tempArrayResults = [
        {
          name: `Financial calendar 2024`,
          url: financialCalendar2024,
        },
        {
          name: `Financial calendar 2025`,
          url: financialCalendar2025,
        },
      ];
      setJsonObject(tempArrayResults);
    });
  }, [downlaodFiles, financialCalendar2024, financialCalendar2025]);
  return (
    <>
      <div className="container wrapper-documents pt-5 mt-5 mb-5 pb-5">
        <div className="internal-wrap pt-5 mt-5 pb-5 mb-5">
          <div className="rigsave-custom-title text-center pb-5">
            {t("main.financialCalendar.title")}
          </div>
          {jsonObject ? (
            <div className="results-wrapper">
              {jsonObject.map((pdf, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-6 card-documents px-5 pt-4 pb-4 mx-5 mb-5"
                  >
                    <div className="title-documents">{pdf.name}</div>
                    <Link
                      to={pdf.url}
                      className="button-contact final-a list-inline d-block text-center"
                      target="_blank"
                    >
                      Report
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="container justify-content-center text-center">
              <RotatingLines
                strokeColor="#6b97cf"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FinancialCalendar;
