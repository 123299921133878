import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Link } from "react-router-dom";

import "../css/Model231.css";

const aws_region = process.env.REACT_APP_AWS_REGION;

const cred = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const s3Client = new S3Client({
  region: aws_region,
  credentials: cred,
});

function Model231() {
  const { t } = useTranslation();

  const [jsonObject, setJsonObject] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const [a2, setA2] = useState("");
  const [a3, setA3] = useState("");
  const [a4, setA4] = useState("");
  const [a5, setA5] = useState("");
  const [a6, setA6] = useState("");

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);

    const filteredItems = jsonObject.filter((pdf) =>
      pdf.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  };

  const bucket_name = process.env.REACT_APP_S3_BUCKET_NAME;

  const getSignedUrlForFile = async (key) => {
    const command = new GetObjectCommand({
      Bucket: bucket_name,
      Key: key,
    });

    try {
      const signedUrl = await getSignedUrl(s3Client, command);
      return signedUrl;
    } catch (error) {
      console.error("Error generating signed URL:", error);
      return null;
    }
  };

  async function downlaodFiles() {
    setA2(await getSignedUrlForFile("model_231/A2.pdf"));
    setA3(await getSignedUrlForFile("model_231/A3.pdf"));
    setA4(await getSignedUrlForFile("model_231/A4.pdf"));
    setA5(await getSignedUrlForFile("model_231/A5.pdf"));
    setA6(await getSignedUrlForFile("model_231/A6.pdf"));
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]).then((responses) => {
      const tempArrayResults = [
        {
          name: `${t("main.model231.docuementTitle")} - 1`,
          url: a2,
        },
        {
          name: `${t("main.model231.docuementTitle")} - 2`,
          url: a3,
        },
        {
          name: `${t("main.model231.docuementTitle")} - 3`,
          url: a4,
        },
        {
          name: `${t("main.model231.docuementTitle")} - 4`,
          url: a5,
        },
        {
          name: `${t("main.model231.docuementTitle")} - 5`,
          url: a6,
        },
      ];
      setFilteredData(tempArrayResults);
      setJsonObject(tempArrayResults);
    });
  }, []);
  return (
    <>
      <div className="container wrapper-documents pt-5 mt-5 mb-5 pb-5">
        <div className="internal-wrap pt-5 mt-5 pb-5 mb-5">
          <div className="rigsave-custom-title text-center pb-5">
            {t("main.model231.title")}
          </div>
          <div className="text-center pb-5 mb-4">
            <input
              type="text"
              className="input-search"
              onChange={handleInputChange}
              value={searchValue}
            />
          </div>
          {filteredData ? (
            <div className="results-wrapper">
              {filteredData.map((pdf, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-6 card-documents px-5 pt-4 pb-4 mx-5 mb-5"
                  >
                    <div className="title-documents">{pdf.name}</div>
                    <Link
                      to={pdf.url}
                      className="button-contact final-a list-inline d-block text-center"
                      target="_blank"
                    >
                      PDF
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="container justify-content-center text-center">
              <RotatingLines
                strokeColor="#6b97cf"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Model231;
