import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Link } from "react-router-dom";

import "../css/InclusionDocument.css";

const aws_region = process.env.REACT_APP_AWS_REGION;

const cred = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const s3Client = new S3Client({
  region: aws_region,
  credentials: cred,
});

function CorporateActions() {
  const { t } = useTranslation();

  const [jsonObject, setJsonObject] = useState([]);

  const [capitalIncrease27012025_IT, setCapitalIncrease27012025_IT] =
    useState("");
  const [capitalIncrease27012025_EN, setCapitalIncrease27012025_EN] =
    useState("");
  const [conclusionCapitalIncrease_IT, setConclusionCapitalIncrease_IT] =
    useState("");
  const [conclusionCapitalIncrease_EN, setConclusionCapitalIncrease_EN] =
    useState("");
  const [
    notaryDeedCapitalIncrease21012025_IT,
    setNotaryDeedCapitalIncrease21012025_IT,
  ] = useState("");
  const [
    notaryDeedCapitalIncrease21012025_EN,
    setNotaryDeedCapitalIncrease21012025_EN,
  ] = useState("");
  const [minutesBodAcn_IT, setMinutesBodAcn_IT] = useState("");
  const [minutesBodAcn_EN, setMinutesBodAcn_EN] = useState("");
  const [
    capitalIncreaseSubscriptionForm_IT,
    setCapitalIncreaseSubscriptionForm_IT,
  ] = useState("");
  const [
    capitalIncreaseSubscriptionForm_EN,
    setCapitalIncreaseSubscriptionForm_EN,
  ] = useState("");
  const [prospectusUnexercisedRights_EN, setProspectusUnexercisedRights_EN] =
    useState("");
  const [prospectusUnexercisedRights_IT, setProspectusUnexercisedRights_IT] =
    useState("");
  const [pressReleaseCapitalIncrease_IT, setPressReleaseCapitalIncrease_IT] =
    useState("");
  const [pressReleaseCapitalIncrease_EN, setPressReleaseCapitalIncrease_EN] =
    useState("");

  const bucket_name = process.env.REACT_APP_S3_BUCKET_NAME;

  const getSignedUrlForFile = async (key) => {
    const command = new GetObjectCommand({
      Bucket: bucket_name,
      Key: key,
    });

    try {
      const signedUrl = await getSignedUrl(s3Client, command);
      return signedUrl;
    } catch (error) {
      console.error("Error generating signed URL:", error);
      return null;
    }
  };

  async function downlaodFiles() {
    setCapitalIncrease27012025_IT(
      await getSignedUrlForFile(
        "corporate-actions/capital_increase_27012025_IT.pdf"
      )
    );
    setCapitalIncrease27012025_EN(
      await getSignedUrlForFile(
        "corporate-actions/capital_increase_27012025_EN.pdf"
      )
    );
    setNotaryDeedCapitalIncrease21012025_IT(
      await getSignedUrlForFile(
        "corporate-actions/20250121_capital_increase_notary_deed.pdf"
      )
    );
    setNotaryDeedCapitalIncrease21012025_EN(
      await getSignedUrlForFile(
        "corporate-actions/20250121_capital_increase_notary_deed_EN.pdf"
      )
    );
    setMinutesBodAcn_IT(
      await getSignedUrlForFile(
        "corporate-actions/verbale_presa_atto_cda_IT.pdf"
      )
    );
    setMinutesBodAcn_EN(
      await getSignedUrlForFile(
        "corporate-actions/verbale_presa_atto_cda_EN.pdf"
      )
    );
    setCapitalIncreaseSubscriptionForm_IT(
      await getSignedUrlForFile(
        "corporate-actions/CapitalIncreaseSubscriptionForm_IT.pdf"
      )
    );
    setCapitalIncreaseSubscriptionForm_EN(
      await getSignedUrlForFile(
        "corporate-actions/CapitalIncreaseSubscriptionForm_EN.pdf"
      )
    );
    setProspectusUnexercisedRights_EN(
      await getSignedUrlForFile(
        "corporate-actions/prospectusUnexercisedRights_IT.pdf"
      )
    );
    setProspectusUnexercisedRights_IT(
      await getSignedUrlForFile(
        "corporate-actions/prospectusUnexercisedRights_IT.pdf"
      )
    );
    setPressReleaseCapitalIncrease_IT(
      await getSignedUrlForFile(
        "corporate-actions/pressReleaseCapitalIncrease_IT.pdf"
      )
    );
    setPressReleaseCapitalIncrease_EN(
      await getSignedUrlForFile(
        "corporate-actions/pressReleaseCapitalIncrease_EN.pdf"
      )
    );
    setConclusionCapitalIncrease_IT(
      await getSignedUrlForFile(
        "corporate-actions/conclusionCapitalIncrease_IT.pdf"
      )
    );
    setConclusionCapitalIncrease_EN(
      await getSignedUrlForFile(
        "corporate-actions/conclusionCapitalIncrease_EN.pdf"
      )
    );
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]).then((responses) => {
      const tempArrayResults = [
        {
          date: ``,
          name: `Prospetto diritti inoptati ISIN IT0005634164`,
          url: prospectusUnexercisedRights_IT,
        },
        {
          date: ``,
          name: `Prospectus of unexercised rights ISIN IT0005634164`,
          url: prospectusUnexercisedRights_EN,
        },
        {
          date: ``,
          name: `Comunicato stampa aumento di capitale`,
          url: pressReleaseCapitalIncrease_IT,
        },
        {
          date: ``,
          name: `Press release on capital increase`,
          url: pressReleaseCapitalIncrease_EN,
        },
        {
          date: `24 ${t("main.investorSection.january")} 2025`,
          name: `Atto notarile di aumento di capitale`,
          url: notaryDeedCapitalIncrease21012025_IT,
        },
        {
          date: `24 ${t("main.investorSection.january")} 2025`,
          name: `Notary deed capital increase`,
          url: notaryDeedCapitalIncrease21012025_EN,
        },
        {
          name: `Verbale di presa d'atto del CDA`,
          date: `24 ${t("main.investorSection.january")} 2025`,
          url: minutesBodAcn_IT,
        },
        {
          name: `Board of Directors' acknowledgment minutes`,
          date: `24 ${t("main.investorSection.january")} 2025`,
          url: minutesBodAcn_EN,
        },
        {
          name: `Modulo adesione aumento di capitale`,
          date: `24 ${t("main.investorSection.january")} 2025`,
          url: capitalIncreaseSubscriptionForm_IT,
        },
        {
          name: `Capital Increase Subscription Form`,
          date: `24 ${t("main.investorSection.january")} 2025`,
          url: capitalIncreaseSubscriptionForm_EN,
        },
        {
          name: `Aumento di capitale Rigsave S.p.A.`,
          date: ``,
          url: capitalIncrease27012025_IT,
        },
        {
          name: `Capital increase of Rigsave S.p.A.`,
          date: ``,
          url: capitalIncrease27012025_EN,
        },
        {
          name: `Conclusione aumento di capitale Rigsave S.p.A.`,
          date: ``,
          url: conclusionCapitalIncrease_IT,
        },
        {
          name: `Conclusion of capital increase of Rigsave S.p.A.`,
          date: ``,
          url: conclusionCapitalIncrease_EN,
        },
      ];
      setJsonObject(tempArrayResults);
    });
  }, [
    capitalIncrease27012025_EN,
    capitalIncrease27012025_IT,
    capitalIncreaseSubscriptionForm_EN,
    capitalIncreaseSubscriptionForm_IT,
    conclusionCapitalIncrease_EN,
    conclusionCapitalIncrease_IT,
    downlaodFiles,
    minutesBodAcn_EN,
    minutesBodAcn_IT,
    notaryDeedCapitalIncrease21012025_EN,
    notaryDeedCapitalIncrease21012025_IT,
    pressReleaseCapitalIncrease_EN,
    pressReleaseCapitalIncrease_IT,
    prospectusUnexercisedRights_EN,
    prospectusUnexercisedRights_IT,
    t,
  ]);
  return (
    <>
      <div className="inclusion-document container wrapper-documents pt-5 mt-5 mb-5 pb-5">
        <div className="internal-wrap pt-5 mt-5 pb-5 mb-5">
          <div className="rigsave-custom-title text-center pb-5">
            {t("main.corporateActions.title")}
          </div>
          {jsonObject ? (
            <div className="results-wrapper">
              {jsonObject.map((pdf, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-6 card-documents px-5 pt-4 pb-4 mx-5 mb-5"
                  >
                    <div className="title-documents">{pdf.name}</div>
                    <div className="date-documents">{pdf.date}</div>
                    <Link
                      to={pdf.url}
                      className="button-contact final-a list-inline d-block text-center"
                      target="_blank"
                    >
                      PDF
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="container justify-content-center text-center">
              <RotatingLines
                strokeColor="#6b97cf"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CorporateActions;
