import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Tab, Tabs } from "react-bootstrap";

import "../css/Tab.css";
import { Link } from "react-router-dom";

const aws_region = process.env.REACT_APP_AWS_REGION;

const cred = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const s3Client = new S3Client({
  region: aws_region,
  credentials: cred,
});

function DirectorDealings() {
  const { t } = useTranslation();
  const [key, setKey] = useState("0");

  const [jsonObject, setJsonObject] = useState([]);

  const [sGervasiDDealings1, setsGervasiDDealings1] = useState("");
  const [sGervasiDDealings2, setsGervasiDDealings2] = useState("");
  const [sGervasiDDealings3, setsGervasiDDealings3] = useState("");
  const [sGervasiDDealings4, setsGervasiDDealings4] = useState("");
  const [sGervasiDDealings5, setsGervasiDDealings5] = useState("");
  const [sGervasiDDealings6, setsGervasiDDealings6] = useState("");
  const [sGervasiDDealings7, setsGervasiDDealings7] = useState("");
  const [sGervasiDDealings8, setsGervasiDDealings8] = useState("");
  const [sGervasiDDealings9, setsGervasiDDealings9] = useState("");
  const [sGervasiDDealings10, setsGervasiDDealings10] = useState("");
  const [sGervasiDDealings11, setsGervasiDDealings11] = useState("");
  const [sGervasiDDealings100524, setsGervasiDDealings100524] = useState("");
  const [sGervasiDDealings130524, setsGervasiDDealings130524] = useState("");
  const [sGervasiDDealings140524, setsGervasiDDealings140524] = useState("");
  const [sGervasiDDealings150524, setsGervasiDDealings150524] = useState("");
  const [sGervasiDDealingsAmendment, setsGervasiDDealingsAmendment] =
    useState("");

  const [mBasilicataDDealings1, setmBasilicataDDealings1] = useState("");
  const [mBasilicataDDealings2, setmBasilicataDDealings2] = useState("");
  const [mBasilicataDDealings3, setmBasilicataDDealings3] = useState("");
  const [mBasilicataDDealings4, setmBasilicataDDealings4] = useState("");
  const [mBasilicataDDealings5, setmBasilicataDDealings5] = useState("");
  const [mBasilicataDDealings6, setmBasilicataDDealings6] = useState("");
  const [mBasilicataDDealings7, setmBasilicataDDealings7] = useState("");
  const [mBasilicataDDealings8, setmBasilicataDDealings8] = useState("");
  const [mBasilicataDDealings9, setmBasilicataDDealings9] = useState("");
  const [mBasilicataDDealings10, setmBasilicataDDealings10] = useState("");
  const [mBasilicataDDealings11, setmBasilicataDDealings11] = useState("");
  const [mBasilicataDDealings12, setmBasilicataDDealings12] = useState("");
  const [mBasilicataDDealings13, setmBasilicataDDealings13] = useState("");

  const [redAntCapital, setredAntCapital] = useState("");
  const [redAntCapital1, setredAntCapital1] = useState("");
  const [redAntCapital2, setredAntCapital2] = useState("");
  const [redAntCapital3, setredAntCapital3] = useState("");
  const [redAntCapital150524, setredAntCapital150524] = useState("");
  const [redAntCapital170524, setredAntCapital170524] = useState("");

  const [rigsaveAbsoluteAlphaFund, setRigsaveAbsoluteAlphaFund] = useState("");
  const [rigsaveAbsoluteAlphaFund1, setRigsaveAbsoluteAlphaFund1] =
    useState("");
  const [rigsaveAbsoluteAlphaFund2, setRigsaveAbsoluteAlphaFund2] =
    useState("");
  const [rigsaveAbsoluteAlphaFund3, setRigsaveAbsoluteAlphaFund3] =
    useState("");
  const [rigsaveAbsoluteAlphaFund4, setRigsaveAbsoluteAlphaFund4] =
    useState("");
  const [rigsaveAbsoluteAlphaFund5, setRigsaveAbsoluteAlphaFund5] =
    useState("");
  const [rigsaveAbsoluteAlphaFund6, setRigsaveAbsoluteAlphaFund6] =
    useState("");
  const [rigsaveAbsoluteAlphaFund7, setRigsaveAbsoluteAlphaFund7] =
    useState("");
  const [rigsaveAbsoluteAlphaFund8, setRigsaveAbsoluteAlphaFund8] =
    useState("");
  const [rigsaveAbsoluteAlphaFund160524, setRigsaveAbsoluteAlphaFund160524] =
    useState("");
  const [rigsaveAbsoluteAlphaFund240524, setRigsaveAbsoluteAlphaFund240524] =
    useState("");
  const [rigsaveAbsoluteAlphaFund270524, setRigsaveAbsoluteAlphaFund270524] =
    useState("");

  const [rigsaveFundSICAV, setRigsaveFundSICAV] = useState("");
  const [rigsaveFundSICAV1, setRigsaveFundSICAV1] = useState("");
  const [rigsaveFundSICAV270524, setRigsaveFundSICAV270524] = useState("");
  const [rigsaveFundSICAV090524, setRigsaveFundSICAV090524] = useState("");
  const [rigsaveFundSICAV090524_2, setRigsaveFundSICAV090524_2] = useState("");

  const bucket_name = process.env.REACT_APP_S3_BUCKET_NAME;

  const getSignedUrlForFile = async (key) => {
    const command = new GetObjectCommand({
      Bucket: bucket_name,
      Key: key,
    });

    try {
      const signedUrl = await getSignedUrl(s3Client, command);
      return signedUrl;
    } catch (error) {
      console.error("Error generating signed URL:", error);
      return null;
    }
  };

  async function downlaodFiles() {
    setsGervasiDDealings1(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_1.pdf"
      )
    );
    setsGervasiDDealings2(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_2.pdf"
      )
    );
    setsGervasiDDealings3(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_3.pdf"
      )
    );
    setsGervasiDDealings4(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_4.pdf"
      )
    );
    setsGervasiDDealings5(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_5.pdf"
      )
    );
    setsGervasiDDealings6(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_6.pdf"
      )
    );
    setsGervasiDDealings7(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_7.pdf"
      )
    );
    setsGervasiDDealings8(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_8.pdf"
      )
    );
    setsGervasiDDealings9(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_9.pdf"
      )
    );
    setsGervasiDDealings10(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_10.pdf"
      )
    );
    setsGervasiDDealings11(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/gervasi_11.pdf"
      )
    );
    setsGervasiDDealings100524(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/2024_05_10 gervasi.pdf"
      )
    );
    setsGervasiDDealings130524(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/2024_05_13_gervasi.pdf"
      )
    );
    setsGervasiDDealings140524(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/2024_05_14_gervasi.pdf"
      )
    );
    setsGervasiDDealings150524(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/2024_05_15_gervasi.pdf"
      )
    );
    setsGervasiDDealingsAmendment(
      await getSignedUrlForFile(
        "director_dealings/2023/s_gervasi/sg_amendment.pdf"
      )
    );

    setmBasilicataDDealings1(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_1.pdf"
      )
    );
    setmBasilicataDDealings2(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_2.pdf"
      )
    );
    setmBasilicataDDealings3(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_3.pdf"
      )
    );
    setmBasilicataDDealings4(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_4.pdf"
      )
    );
    setmBasilicataDDealings5(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_5.pdf"
      )
    );
    setmBasilicataDDealings6(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_6.pdf"
      )
    );
    setmBasilicataDDealings7(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_7.pdf"
      )
    );
    setmBasilicataDDealings8(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_8.pdf"
      )
    );
    setmBasilicataDDealings9(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_9.pdf"
      )
    );
    setmBasilicataDDealings10(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_10.pdf"
      )
    );
    setmBasilicataDDealings11(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_11.pdf"
      )
    );
    setmBasilicataDDealings12(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_12.pdf"
      )
    );
    setmBasilicataDDealings13(
      await getSignedUrlForFile(
        "director_dealings/2023/m_basilicata/basilicata_13.pdf"
      )
    );

    setredAntCapital(
      await getSignedUrlForFile(
        "director_dealings/2023/red_ant_capital/red_ant_capital_srl.pdf"
      )
    );
    setredAntCapital1(
      await getSignedUrlForFile(
        "director_dealings/2023/red_ant_capital/red_ant_capital_srl1.pdf"
      )
    );
    setredAntCapital2(
      await getSignedUrlForFile(
        "director_dealings/2023/red_ant_capital/red_ant_capital_srl2.pdf"
      )
    );
    setredAntCapital3(
      await getSignedUrlForFile(
        "director_dealings/2023/red_ant_capital/red_ant_capital_srl3.pdf"
      )
    );
    setredAntCapital150524(
      await getSignedUrlForFile(
        "director_dealings/2023/red_ant_capital/2024_05_15_red_ant.pdf"
      )
    );
    setredAntCapital170524(
      await getSignedUrlForFile(
        "director_dealings/2023/red_ant_capital/2024_05_17_red_ant.pdf"
      )
    );

    setRigsaveAbsoluteAlphaFund(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf"
      )
    );
    setRigsaveAbsoluteAlphaFund1(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf1"
      )
    );
    setRigsaveAbsoluteAlphaFund2(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf2"
      )
    );
    setRigsaveAbsoluteAlphaFund3(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf3"
      )
    );
    setRigsaveAbsoluteAlphaFund4(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf4"
      )
    );
    setRigsaveAbsoluteAlphaFund5(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf5"
      )
    );
    setRigsaveAbsoluteAlphaFund6(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf6"
      )
    );
    setRigsaveAbsoluteAlphaFund7(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf7"
      )
    );
    setRigsaveAbsoluteAlphaFund8(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf8"
      )
    );
    setRigsaveAbsoluteAlphaFund160524(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/2024_05_16_raaf.pdf"
      )
    );
    setRigsaveAbsoluteAlphaFund240524(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/2024_05_24_raaf.pdf"
      )
    );
    setRigsaveAbsoluteAlphaFund270524(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_absolute_alpha_fund/2024_05_27_raaf.pdf"
      )
    );

    setRigsaveFundSICAV(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_fund_sicav/rigsave_fund_sicav.pdf"
      )
    );
    setRigsaveFundSICAV1(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_fund_sicav/rigsave_fund_sicav1.pdf"
      )
    );
    setRigsaveFundSICAV270524(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_fund_sicav/2024_05_07_sicav.pdf"
      )
    );
    setRigsaveFundSICAV090524(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_fund_sicav/2024_05_09_sicav.pdf"
      )
    );
    setRigsaveFundSICAV090524_2(
      await getSignedUrlForFile(
        "director_dealings/2023/rigsave_fund_sicav/2024_05_09_sicav_2.pdf"
      )
    );
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]).then((responses) => {
      const arrayTemp = [
        {
          owner: "Salvatore Gervasi",
          documents: [
            {
              date: `10 ${t("main.investorSection.may")} 2024`,
              url: sGervasiDDealings100524,
            },
            {
              date: `13 ${t("main.investorSection.may")} 2024`,
              url: sGervasiDDealings130524,
            },
            {
              date: `14 ${t("main.investorSection.may")} 2024`,
              url: sGervasiDDealings140524,
            },
            {
              date: `15 ${t("main.investorSection.may")} 2024`,
              url: sGervasiDDealings150524,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 1`,
              url: sGervasiDDealings1,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 2`,
              url: sGervasiDDealings2,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 3`,
              url: sGervasiDDealings3,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 4`,
              url: sGervasiDDealings4,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 5`,
              url: sGervasiDDealings5,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 6`,
              url: sGervasiDDealings6,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 7`,
              url: sGervasiDDealings7,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 8`,
              url: sGervasiDDealings8,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 9`,
              url: sGervasiDDealings9,
            },
            {
              date: `23 ${t("main.investorSection.march")} 2024`,
              url: sGervasiDDealings10,
            },
            {
              date: `30 ${t("main.investorSection.april")} 2024`,
              url: sGervasiDDealings11,
            },
            {
              date: `29 ${t("main.investorSection.march")} 2024`,
              url: sGervasiDDealingsAmendment,
            },
          ],
        },
        {
          owner: "Michele Basilicata",
          documents: [
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 1`,
              url: mBasilicataDDealings1,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 2`,
              url: mBasilicataDDealings2,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 3`,
              url: mBasilicataDDealings3,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 4`,
              url: mBasilicataDDealings4,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 5`,
              url: mBasilicataDDealings5,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 6`,
              url: mBasilicataDDealings6,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 7`,
              url: mBasilicataDDealings7,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 8`,
              url: mBasilicataDDealings8,
            },
            {
              date: `26 ${t("main.investorSection.march")} 2024 - 9`,
              url: mBasilicataDDealings9,
            },
            {
              date: `10 ${t("main.investorSection.april")} 2024`,
              url: mBasilicataDDealings10,
            },
            {
              date: `23 ${t("main.investorSection.april")} 2024 - 1`,
              url: mBasilicataDDealings11,
            },
            {
              date: `23 ${t("main.investorSection.april")} 2024 - 2`,
              url: mBasilicataDDealings12,
            },
            {
              date: `23 ${t("main.investorSection.april")} 2024 - 3`,
              url: mBasilicataDDealings13,
            },
          ],
        },
        {
          owner: "Andrea Polzot / Red Ant Capital",
          documents: [
            {
              date: `17 ${t("main.investorSection.may")} 2024`,
              url: redAntCapital170524,
            },
            {
              date: `15 ${t("main.investorSection.may")} 2024`,
              url: redAntCapital150524,
            },
            {
              date: `21 ${t("main.investorSection.february")} 2024`,
              url: redAntCapital,
            },
            {
              date: `16 ${t("main.investorSection.january")} 2024`,
              url: redAntCapital1,
            },
            {
              date: `29 ${t("main.investorSection.december")} 2024`,
              url: redAntCapital3,
            },
            {
              date: `22 ${t("main.investorSection.december")} 2024`,
              url: redAntCapital2,
            },
          ],
        },
        {
          owner: "Andrea Polzot / Rigsave Absolute Alpha Fund",
          documents: [
            {
              date: `16 ${t("main.investorSection.may")} 2024`,
              url: rigsaveAbsoluteAlphaFund160524,
            },
            {
              date: `24 ${t("main.investorSection.may")} 2024`,
              url: rigsaveAbsoluteAlphaFund240524,
            },
            {
              date: `27 ${t("main.investorSection.may")} 2024`,
              url: rigsaveAbsoluteAlphaFund270524,
            },
            {
              date: `13 ${t("main.investorSection.may")} 2024 - 1`,
              url: rigsaveAbsoluteAlphaFund7,
            },
            {
              date: `13 ${t("main.investorSection.may")} 2024 - 2`,
              url: rigsaveAbsoluteAlphaFund8,
            },
            {
              date: `23 ${t("main.investorSection.april")} 2024 - 1`,
              url: rigsaveAbsoluteAlphaFund5,
            },
            {
              date: `23 ${t("main.investorSection.april")} 2024 - 2`,
              url: rigsaveAbsoluteAlphaFund6,
            },
            {
              date: `10 ${t("main.investorSection.april")} 2024`,
              url: rigsaveAbsoluteAlphaFund,
            },
            {
              date: `04 ${t("main.investorSection.april")} 2024 - 1`,
              url: rigsaveAbsoluteAlphaFund1,
            },
            {
              date: `04 ${t("main.investorSection.april")} 2024 - 2`,
              url: rigsaveAbsoluteAlphaFund4,
            },
            {
              date: `12 ${t("main.investorSection.march")} 2024`,
              url: rigsaveAbsoluteAlphaFund2,
            },
            {
              date: `05 ${t("main.investorSection.march")} 2024`,
              url: rigsaveAbsoluteAlphaFund3,
            },
          ],
        },
        {
          owner: "Rigsave Fund SICAV",
          documents: [
            {
              date: `27 ${t("main.investorSection.may")} 2024`,
              url: rigsaveFundSICAV270524,
            },
            {
              date: `09 ${t("main.investorSection.may")} 2024 - 1`,
              url: rigsaveFundSICAV090524,
            },
            {
              date: `09 ${t("main.investorSection.may")} 2024 - 2`,
              url: rigsaveFundSICAV090524_2,
            },
            {
              date: `21 ${t("main.investorSection.february")} 2024`,
              url: rigsaveFundSICAV,
            },
            {
              date: `16 ${t("main.investorSection.february")} 2024 - 2`,
              url: rigsaveFundSICAV1,
            },
          ],
        },
      ];
      setJsonObject(arrayTemp);
    });
  }, [
    downlaodFiles,
    mBasilicataDDealings1,
    mBasilicataDDealings10,
    mBasilicataDDealings11,
    mBasilicataDDealings12,
    mBasilicataDDealings13,
    mBasilicataDDealings2,
    mBasilicataDDealings3,
    mBasilicataDDealings4,
    mBasilicataDDealings5,
    mBasilicataDDealings6,
    mBasilicataDDealings7,
    mBasilicataDDealings8,
    mBasilicataDDealings9,
    redAntCapital,
    redAntCapital1,
    redAntCapital150524,
    redAntCapital170524,
    redAntCapital2,
    redAntCapital3,
    rigsaveAbsoluteAlphaFund,
    rigsaveAbsoluteAlphaFund1,
    rigsaveAbsoluteAlphaFund160524,
    rigsaveAbsoluteAlphaFund2,
    rigsaveAbsoluteAlphaFund240524,
    rigsaveAbsoluteAlphaFund270524,
    rigsaveAbsoluteAlphaFund3,
    rigsaveAbsoluteAlphaFund4,
    rigsaveAbsoluteAlphaFund5,
    rigsaveAbsoluteAlphaFund6,
    rigsaveAbsoluteAlphaFund7,
    rigsaveAbsoluteAlphaFund8,
    rigsaveFundSICAV,
    rigsaveFundSICAV090524,
    rigsaveFundSICAV090524_2,
    rigsaveFundSICAV1,
    rigsaveFundSICAV270524,
    sGervasiDDealings1,
    sGervasiDDealings10,
    sGervasiDDealings100524,
    sGervasiDDealings11,
    sGervasiDDealings130524,
    sGervasiDDealings140524,
    sGervasiDDealings150524,
    sGervasiDDealings2,
    sGervasiDDealings3,
    sGervasiDDealings4,
    sGervasiDDealings5,
    sGervasiDDealings6,
    sGervasiDDealings7,
    sGervasiDDealings8,
    sGervasiDDealings9,
    sGervasiDDealingsAmendment,
    t,
  ]);

  return (
    <>
      <div className="container director-dealings mt-5 pt-5 pb-5 mb-5">
        <div className="internal-wrap pt-5 mt-5 pb-5 mb-5">
          <div className="rigsave-custom-title text-center pb-5">
            {t("main.directorDealings.title")}
          </div>
          {jsonObject && (
            <Tabs
              defaultActiveKey="profile"
              id="tab-directors-dealings"
              className="mb-5 pb-4"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              {jsonObject.map((item, index) => (
                <Tab key={index} eventKey={index} title={item.owner}>
                  {item.documents.map((doc, ind) => {
                    return (
                      <div className="card-documents mb-4 mt-4 mx-5" key={ind}>
                        <div className="title pb-5">{doc.date}</div>
                        <Link
                          className="pdf-button"
                          to={doc.url}
                          target="_blank"
                        >
                          PDF
                        </Link>
                      </div>
                    );
                  })}
                </Tab>
              ))}
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
}

export default DirectorDealings;
