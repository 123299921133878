import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import esg from "../assets/esg.png";
import laRepubblicaLogo from "../assets/photos/logo-repubblica.png";
import mondoefinanzaLogo from "../assets/photos/mondoefinanza_logo_2x.png";

import "../css/Home.css";
import TradingViewWidget from "components/TradingViewWidget";

function Home() {
  const { t } = useTranslation();

  return (
    <div id="home">
      <div className="hero-home">
        <div className="text-hero-home">
          <div className="text pb-5 px-4">
            THE INTEGRATED SERVICE THAT HELPS YOU SIMPLIFY THE MANAGEMENT OF
            YOUR FINANCES.
          </div>
          <div className="wrapper-who-we-are">
            <div className="d-lg-flex justify-content-center text-center pb-2">
              <div className="card-hero-home px-5">
                <p>2018</p>
                <p>{t("main.home.allStarted")}</p>
              </div>
              {/* <div className="card-hero-home px-5">
                <p>€341{t("main.home.mila")}</p>
                <p>{t("main.home.revenue")}2023</p>
              </div> */}
              <div className="card-hero-home px-5">
                <p>2023</p>
                <p>{t("main.home.stockListing")}</p>
              </div>
            </div>
            <div className="wrapper-box p-4 mt-5">
              <p className="rigsave-custom-title text-center pb-2">
                {t("main.home.whoWeAre")}
              </p>
              <p>{t("main.home.whoWeAreText")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-content-home">
        <div className="container pt-5">
          <div className="container-stocks">
            <TradingViewWidget />
          </div>
        </div>
        <div className="container justify-content-center container-news pt-5 pb-5 mt-4">
          <p className="rigsave-custom-title text-center pb-4">
            {t("main.home.aboutUs")}
          </p>
          <div className="d-lg-flex">
            <div className="news col-lg-6 me-2">
              <img
                src={laRepubblicaLogo}
                className="pb-5"
                alt="LaRepubblica logo"
              />
              <Link
                to={
                  "https://finanza.repubblica.it/Pages/News/Item.aspx?ID=93_2023-12-18_TLB"
                }
                className="list-inline d-block text-center"
              >
                {t("main.home.read")}
              </Link>
            </div>
            <div className="news col-lg-6 ms-2">
              <img
                src={mondoefinanzaLogo}
                className="pb-5"
                alt="Mondoefinanza logo"
              />
              <Link
                to={
                  "https://www.mondoefinanza.it/2020/02/01/rigsave-costituisce-la-spa-holding-del-gruppo-per-la-futura-quotazione-in-borsa-valori/"
                }
                className="list-inline d-block text-center"
              >
                {t("main.home.read")}
              </Link>
            </div>
          </div>
        </div>
        <div className="wrapper-sustainability mt-5 pt-5 mb-5">
          <div className="text-hero-home">
            <div className="text pb-5">
              <p>{t("main.home.sustainability")}</p>
              <p className="text-start">{t("main.home.sustainabilityText")}</p>
            </div>
            <div className="wrapper-esg text-center">
              <img width="100" src={esg} alt="ESG logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
