import "./App.css";
import { Routes, Route } from "react-router-dom";
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import { Suspense } from "react";

import Home from "pages/Home";
import Header from "components/Header";
import Footer from "components/Footer";
import AboutUs from "pages/AboutUs";
import Services from "pages/Services";
import ContactUs from "pages/ContactUS";
import ShareholdersMeetings from "pages/ShareholdersMeetings";
import Presentations from "pages/Presentations";
import GoTop from "components/GoTop";
import ScrollToTop from "components/ScrollToTop";
import DirectorDealings from "pages/DirectorDealings";
import FinancialReports from "pages/FinancialReports";
import FinancialCalendar from "pages/FinancialCalendar";
import AnalystReport from "pages/AnalystReports";
import InclusionDocument from "pages/InclusionDocument";
import NotFound from "pages/NotFound";
import Model231 from "pages/Model231";
import CorporateActions from "pages/CorporateActions";
import AdHocReleases from "pages/AdHocReleases";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <div className="wrapper-main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/presentations" element={<Presentations />} />
          <Route path="/director-dealings" element={<DirectorDealings />} />
          <Route path="/financial-reports" element={<FinancialReports />} />
          <Route path="/financial-calendar" element={<FinancialCalendar />} />
          <Route path="/corporate-actions" element={<CorporateActions />} />
          <Route path="/model-231" element={<Model231 />} />
          <Route
            path="/shareholders-meetings"
            element={<ShareholdersMeetings />}
          />
          <Route path="/inclusion-document" element={<InclusionDocument />} />
          <Route path="/adhoc-releases" element={<AdHocReleases />} />
          <Route path="/analyst-reports" element={<AnalystReport />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <GoTop />
      <Footer />
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}
