import React, { useState } from "react";

import companyLogo from "../assets/logo.png";
import { useTranslation } from "react-i18next";

import { Link, NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";

import { useMediaQuery } from "react-responsive";

import "../css/Navbar.css";
import "../css/Header.css";
import { Accordion, Dropdown } from "react-bootstrap";
import TranslatorComponent from "./TranslatorComponent";

function Header() {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1150px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1150px)" });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: "1150px" });
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const renderNavLinks = () => {
    const listClassName = isMobile ? "nav__list" : "nav__list__web";
    const linkClassName = "nav__link";
    //const buttonClassName = "nav__cta";

    return (
      <ul className={listClassName}>
        <li>
          <NavLink to="/" className={linkClassName} onClick={closeMobileMenu}>
            {t("main.header.home")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about-us"
            className={linkClassName}
            onClick={closeMobileMenu}
          >
            {t("main.header.aboutUs")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/services"
            className={linkClassName}
            onClick={closeMobileMenu}
          >
            {t("main.header.services")}
          </NavLink>
        </li>
        {isTabletOrMobile && (
          <Accordion className="accordion-mobile-header">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t("main.header.servicesDropdown.investorRelations")}
              </Accordion.Header>
              <Accordion.Body>
                <Link to={"/presentations"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.presentations")}
                </Link>
                <Link to={"/director-dealings"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.directorDealings")}
                </Link>
                <Link to={"/financial-reports"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.financialReports")}
                </Link>
                <Link to={"/analyst-reports"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.analystReports")}
                </Link>
                <Link to={"/financial-calendar"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.financialCalendar")}
                </Link>
                <Link to={"/inclusion-document"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.inclusionDocument")}
                </Link>
                <Link to={"/corporate-actions"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.corporateActions")}
                </Link>
                <Link to={"/adhoc-releases"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.adHocReleases")}
                </Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {isDesktop && (
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
          >
            <Dropdown.Toggle className="services-toggle" id="dropdown-services">
              {t("main.header.servicesDropdown.investorRelations")}
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown} className="services-menu">
              <Dropdown.Item href={"/presentations"}>
                {t("main.header.servicesDropdown.presentations")}
              </Dropdown.Item>
              <Dropdown.Item href={"/director-dealings"}>
                {t("main.header.servicesDropdown.directorDealings")}
              </Dropdown.Item>
              <Dropdown.Item href={"/financial-reports"}>
                {t("main.header.servicesDropdown.financialReports")}
              </Dropdown.Item>
              <Dropdown.Item href={"/analyst-reports"}>
                {t("main.header.servicesDropdown.analystReports")}
              </Dropdown.Item>
              <Dropdown.Item href={"/financial-calendar"}>
                {t("main.header.servicesDropdown.financialCalendar")}
              </Dropdown.Item>
              <Dropdown.Item href={"/inclusion-document"}>
                {t("main.header.servicesDropdown.inclusionDocument")}
              </Dropdown.Item>
              <Dropdown.Item href={"/corporate-actions"}>
                {t("main.header.servicesDropdown.corporateActions")}
              </Dropdown.Item>
              <Dropdown.Item href={"/adhoc-releases"}>
                {t("main.header.servicesDropdown.adHocReleases")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <li>
          <NavLink
            to="/shareholders-meetings"
            className={linkClassName}
            onClick={closeMobileMenu}
          >
            {t("main.header.shareholdersMeetings")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact-us"
            className={linkClassName}
            onClick={closeMobileMenu}
          >
            {t("main.header.contacts")}
          </NavLink>
        </li>
        <TranslatorComponent />
      </ul>
    );
  };

  return (
    <header className="header">
      <nav className="nav">
        <NavLink to="/" className="nav__logo">
          <img height={80} src={companyLogo} alt="Rigsave logo" />
        </NavLink>

        {isMobile && (
          <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
            <IoMenu />
          </div>
        )}

        {isMobile ? (
          <div
            className={`nav__menu  ${isMenuOpen ? "show-menu" : ""}`}
            id="nav-menu"
          >
            {renderNavLinks()}
            <div className="nav__close" id="nav-close" onClick={toggleMenu}>
              <IoClose />
            </div>
          </div>
        ) : (
          renderNavLinks()
        )}
      </nav>
    </header>
  );
}

export default Header;
