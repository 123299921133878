import React from "react";
import { Link } from "react-router-dom";

import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pittogramma from "../assets/logo_footer.png";

import "../css/Footer.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="footer-container row row-info pt-4 pb-2">
          <div className="col-lg-2 col-sm-6 col-md-6">
            <div className="pb-1">
              <p className="title-text-footer mb-2">
                {t("main.header.company")}
              </p>
              <Link to={"/"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.home")}</p>
              </Link>
              <Link to={"/about-us"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.aboutUs")}</p>
              </Link>
              <Link to={"/services"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.services")}</p>
              </Link>
              <Link
                to={"/shareholders-meetings"}
                className="list-inline text-start"
              >
                <p className="mb-1">{t("main.header.shareholdersMeetings")}</p>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 col-md-6">
            <div className="pb-1">
              <p className="title-text-footer mb-2">
                {t("main.header.servicesDropdown.investorRelations")}
              </p>
              <Link to={"/presentations"} className="list-inline text-start">
                <p className="mb-1">
                  {t("main.header.servicesDropdown.presentations")}
                </p>
              </Link>
              <Link
                to={"/director-dealings"}
                className="list-inline text-start"
              >
                <p className="mb-1">
                  {t("main.header.servicesDropdown.directorDealings")}
                </p>
              </Link>
              <Link
                to={"/financial-reports"}
                className="list-inline text-start"
              >
                <p className="mb-1">
                  {t("main.header.servicesDropdown.financialReports")}
                </p>
              </Link>
              <Link to={"/analyst-reports"} className="list-inline text-start">
                <p className="mb-1">
                  {t("main.header.servicesDropdown.analystReports")}
                </p>
              </Link>
              <Link
                to={"/financial-calendar"}
                className="list-inline text-start"
              >
                <p className="mb-1">
                  {t("main.header.servicesDropdown.financialCalendar")}
                </p>
              </Link>
              <Link
                to={"/inclusion-document"}
                className="list-inline text-start"
              >
                <p className="mb-1">
                  {t("main.header.servicesDropdown.inclusionDocument")}
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-md-6">
            <div>
              <p className="title-text-footer mb-2">
                {t("main.header.contacts")}
              </p>
            </div>
            <p className="pec-footer">
              <Link
                to={void 0}
                onClick={(e) =>
                  (window.location.href = "mailto:info@rigsave.com")
                }
              >
                info@rigsave.com
              </Link>
            </p>
            <p>C.so Giuseppe Zanardelli 38, Brescia (BS)</p>
            <p>(+39) 03 0375 6520</p>
          </div>
          <div className="col-lg-2 col-sm-6 col-md-6">
            <p className="title-text-footer mb-2">
              {t("main.header.workWithUs")}
            </p>
            <p className="mb-1">
              <Link to={"/contact-us"}>{t("main.header.sendCV")}</Link>
            </p>
          </div>
          <div className="col-lg-1 col-sm-6 col-md-6">
            <div>
              <p className="title-text-footer mb-2">
                {t("main.header.social")}
              </p>
            </div>
            <div className="d-flex">
              {/* <Link target="_blank" to={"https://www.facebook.com/rigsave"}>
                <p className="pe-2">
                  <FontAwesomeIcon
                    className="font-social"
                    icon={faSquareFacebook}
                  />
                </p>
              </Link> */}
              <Link
                target="_blank"
                to={"https://www.linkedin.com/company/rigsave/"}
              >
                <p>
                  <FontAwesomeIcon className="font-social" icon={faLinkedin} />
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-1 col-sm-6 col-md-6 pittogramma-wrapper-footer d-flex justify-content-end">
            <div className="footer-ico-wrapper">
              <img height="120" src={pittogramma} alt="Rigsave pittogramma" />
            </div>
          </div>
        </div>
        <div className="d-lg-flex row row-footer-legal pt-3 pb-3">
          <div className="col-lg-6">
            <p className="title mb-1">Rigsave S.p.A.</p>
            <p>Copyright © Rigsave S.p.A. | {t("main.copyright")}</p>
          </div>
          <div className="col-lg-6 pp-cp">
            <div className="text-end mt-3">
              <Link
                to={"https://www.iubenda.com/privacy-policy/32717822"}
                className="nav-link px-5 text-end pb-3 font-weight-bold"
              >
                Privacy policy
              </Link>
              <Link
                to={
                  "https://www.iubenda.com/privacy-policy/32717822/cookie-policy"
                }
                className="nav-link px-5 text-end font-weight-bold"
              >
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
