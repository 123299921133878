import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

import icon_26 from "../assets/icons/icon_26.png";
import icon_27 from "../assets/icons/icon_27.png";
import icon_3 from "../assets/icons/icon_3.png";
import icon_4 from "../assets/icons/icon_4.png";
import icon_28 from "../assets/icons/icon_28.png";
import icon_29 from "../assets/icons/icon_29.png";

import micheleB from "../assets/Michele_Finale.png";
import salvatoreG from "../assets/Salvatore_Finale.png";

import andromedaLogo from "../assets/andromeda_logo.png";
import rigsaveCapitalLogo from "../assets/rigsavecapital_logo.png";

import "../css/AboutUs.css";

const aws_region = process.env.REACT_APP_AWS_REGION;

const cred = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const s3Client = new S3Client({
  region: aws_region,
  credentials: cred,
});

function AboutUs() {
  const { t } = useTranslation();

  const [ethicalCode, setEthicalCode] = useState("");

  const bucket_name = process.env.REACT_APP_S3_BUCKET_NAME;

  const getSignedUrlForFile = async (key) => {
    const command = new GetObjectCommand({
      Bucket: bucket_name,
      Key: key,
    });

    try {
      const signedUrl = await getSignedUrl(s3Client, command);
      return signedUrl;
    } catch (error) {
      console.error("Error generating signed URL:", error);
      return null;
    }
  };

  async function downlaodFiles() {
    setEthicalCode(
      await getSignedUrlForFile("ethical_code/A8_codice_etico.pdf")
    );
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]);
  }, []);

  return (
    <div className="about-us mt-5 mb-5 pb-5">
      <div className="container pt-5 mt-5">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.aboutUs.ourStory")}
        </div>
        <div className="text font-aboutUs pb-5">
          <Trans>{t("main.aboutUs.ourStoryText")}</Trans>
        </div>
        <div className="rigsave-custom-title text-center pt-5 pb-5">
          {t("main.aboutUs.ourPhilosophy")}
        </div>
        <div className="border-container vision">
          <div className="title">VISION</div>
          <div className="font-aboutUs">
            <Trans>{t("main.aboutUs.vision")}</Trans>
          </div>
        </div>
        <div className="border-container mission mb-5">
          <div className="title">MISSION</div>
          <div className="font-aboutUs">
            <Trans>{t("main.aboutUs.mission")}</Trans>
          </div>
        </div>
        <div className="values-container pt-5">
          <div className="rigsave-custom-title text-center pt-5 pb-5 mb-4">
            {t("main.aboutUs.ourValues")}
          </div>
          <div className="d-lg-flex row justify-content-center pb-5">
            <div className="col-lg-4 pe-5 pb-5">
              <img src={icon_26} alt="Inclusion" />
              <div className="title">{t("main.aboutUs.independeceTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.independeceText")}
              </div>
            </div>
            <div className="col-lg-4 px-5 pb-5">
              <img src={icon_28} alt="Transparency" />
              <div className="title">{t("main.aboutUs.transparencyTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.transparencyText")}
              </div>
            </div>
            <div className="col-lg-4 ps-5 pb-5">
              <img src={icon_29} alt="Concreteness" />
              <div className="title">{t("main.aboutUs.concretenessTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.concretenessText")}
              </div>
            </div>
            <div className="col-lg-4 pe-5 pt-3">
              <img src={icon_27} alt="Inclusion" />
              <div className="title">{t("main.aboutUs.inclusionTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.inclusionText")}
              </div>
            </div>
            <div className="col-lg-4 px-5 pt-3">
              <img src={icon_3} alt="Transparency" />
              <div className="title">
                {t("main.aboutUs.sustainabilityTitle")}
              </div>
              <div className="font-values">
                {t("main.aboutUs.sustainabilityText")}
              </div>
            </div>
            <div className="col-lg-4 ps-5 pt-3">
              <img src={icon_4} alt="Concreteness" />
              <div className="title">{t("main.aboutUs.innovationTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.innovationText")}
              </div>
            </div>
          </div>
          <div className="pt-4 pb-5">
            <div className="rigsave-custom-title text-center pt-5 pb-2 mb-4">
              {t("main.aboutUs.ethicalCode")}
            </div>
            <div className="font-aboutUs pb-2">
              {t("main.aboutUs.ethicalCodeText")}
            </div>
            <div className="d-flex justify-content-center pt-5">
              <Link
                to={ethicalCode}
                className="list-inline link-document d-block text-center"
                target="_blank"
              >
                {t("main.aboutUs.readMore")}
              </Link>
            </div>
          </div>
          <div className="pt-4 pb-5">
            <div className="rigsave-custom-title text-center pt-5 pb-2 mb-4">
              {t("main.aboutUs.model_231")}
            </div>
            <div className="font-aboutUs pb-2">
              {t("main.aboutUs.model_231Text")}
            </div>
            <div className="d-flex justify-content-center pt-5">
              <Link
                to={"/model-231"}
                className="list-inline link-document d-block text-center"
              >
                {t("main.aboutUs.readMore")}
              </Link>
            </div>
          </div>
        </div>
        <div className="group-detail d-lg-flex justify-content-center pt-5 mt-5 mb-5 pb-5">
          <div className="border-container col-lg-5 mx-5">
            <img height="80" src={rigsaveCapitalLogo} alt="Rigsave Capital" />
            <div className="font-aboutUs pt-2">
              {t("main.aboutUs.rigsaveCapitalAboutUs")}
            </div>
          </div>
          <div className="border-container col-lg-5 mx-5">
            <img height="40" src={andromedaLogo} alt="Andromeda Enterprises" />
            <div className="font-aboutUs pt-2">
              <Trans>{t("main.aboutUs.andromedaAboutUs")}</Trans>
            </div>
          </div>
        </div>
        <div className="governance-container px-5 pt-5 pb-5">
          <div className="text-center title pb-4">Governance</div>
          <div className="text-center sub-title pb-3">
            {t("main.aboutUs.shareholder_structure")}
          </div>
          <div className="d-lg-flex row-governance justify-content-center pt-4 pb-4">
            <div className="col-lg-3 px-5">
              <div className="perc pb-3">18,37%</div>
              <div>Michele Basilicata</div>
            </div>
            <div className="col-lg-3 px-5">
              <div className="perc pb-3">16,85%</div>
              <div>Salvatore Gervasi</div>
            </div>
            <div className="col-lg-3 px-5">
              <div className="perc pb-3">14,81%</div>
              <div>Rossana Ruffo</div>
            </div>
            <div className="col-lg-3 px-5">
              <div className="perc pb-3">7,87%</div>
              <div>Tonino Gervasi</div>
            </div>
          </div>
          <div className="text-center board-sub-title sub-title pt-5 pb-3">
            {t("main.aboutUs.director_board")}
          </div>
          <div className="d-lg-flex row-governance directors justify-content-center pt-4">
            <div className="col-lg-6">
              <div className="d-flex justify-content-end px-4">
                <div className="card-gov">
                  <img src={micheleB} alt="Michele Basilicata" />
                  <div className="bold-name">Michele Basilicata</div>
                  <div>Executive director</div>
                  <Link
                    to={
                      "https://www.linkedin.com/in/michele-basilicata-a3b5658b/"
                    }
                  >
                    Linkedin
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-start px-4">
                <div className="card-gov">
                  <img src={salvatoreG} alt="Salvatore Gervasi" />
                  <div className="bold-name">Salvatore Gervasi</div>
                  <div>Executive director</div>
                  <Link
                    to={
                      "https://www.linkedin.com/in/salvatore-gervasi-443404112/"
                    }
                  >
                    Linkedin
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center board-statutory-title sub-title pt-5 pb-3">
            {t("main.aboutUs.director_board")}
          </div>
          <div className="row d-lg-flex row-governance pt-4 pb-4">
            <div className="col-lg-4 px-5 pb-5">
              <div className="perc pb-2">{t("main.aboutUs.president")}</div>
              <div>Vincenzo Atzori</div>
            </div>
            <div className="col-lg-4 px-5 pb-5">
              <div className="perc pb-2">
                {t("main.aboutUs.effective_member")}
              </div>
              <div>Luca Laurini</div>
            </div>
            <div className="col-lg-4 px-5 pb-5">
              <div className="perc pb-2">
                {t("main.aboutUs.effective_member")}
              </div>
              <div>Stefan Colao</div>
            </div>
            <div className="col-lg-4 px-5">
              <div className="perc pb-2">{t("main.aboutUs.sub_member")}</div>
              <div>Silvia Molino</div>
            </div>
            <div className="col-lg-4 px-5">
              <div className="perc pb-2">{t("main.aboutUs.sub_member")}</div>
              <div>Davide Barbieri</div>
            </div>
          </div>
          <div className="row d-lg-flex row-governance pt-4 pb-4">
            <div className="text-center board-sub-title sub-title pt-5 pb-3">
              {t("main.aboutUs.auditor")}
            </div>
            <div className="col-lg-4 px-5">
              <div className="perc pb-2">{t("main.aboutUs.extAuditor")}</div>
              <div>Prof.ssa Elisa Raoli</div>
            </div>
          </div>
          <div className="d-flex button-wrapper justify-content-center pt-5 mt-4">
            <Link
              to={"/contact-us"}
              className="button-contact link-document list-inline d-block text-center"
            >
              {t("main.aboutUs.contactUs")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
