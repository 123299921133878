import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Link } from "react-router-dom";

import "../css/FinancialReports.css";

const aws_region = process.env.REACT_APP_AWS_REGION;

const cred = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const s3Client = new S3Client({
  region: aws_region,
  credentials: cred,
});

function FinancialReports() {
  const { t } = useTranslation();

  const [jsonObject, setJsonObject] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const [ENconsolidatedAudited2021, setENconsolidatedAudited2021] =
    useState("");
  const [ITconsolidatedAudited2021, setITconsolidatedAudited2021] =
    useState("");
  const [ENitalianGAAPAudited2021, setENitalianGAAPAudited2021] = useState("");
  const [ITitalianGAAPAudited2021, setITitalianGAAPAudited2021] = useState("");

  const [ENconsolidatedAudited2022, setENconsolidatedAudited2022] =
    useState("");
  const [ENitalianGAAPAudited2022, setENitalianGAAPAudited2022] = useState("");
  const [ITitalianGAAPAudited2022, setITitalianGAAPAudited2022] = useState("");
  const [ITconsolidatedAudited2022, setITconsolidatedAudited2022] =
    useState("");

  const [ENHalfYearReport, setENHalfYearReport] = useState("");
  const [ITHalfYearReport, setITHalfYearReport] = useState("");
  const [ITConsolidatedAudited2023, setITConsolidatedAudited2023] =
    useState("");
  const [ENConsolidatedAudited2023, setENConsolidatedAudited2023] =
    useState("");
  const [ITItalianGAAPAudited2023, setITItalianGAAPAudited2023] = useState("");

  const [ENHalfYearConsolidated2024, setENHalfYearConsolidated2024] =
    useState("");

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);

    const filteredItems = jsonObject.filter((pdf) =>
      pdf.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  };

  const bucket_name = process.env.REACT_APP_S3_BUCKET_NAME;

  const getSignedUrlForFile = async (key) => {
    const command = new GetObjectCommand({
      Bucket: bucket_name,
      Key: key,
    });

    try {
      const signedUrl = await getSignedUrl(s3Client, command);
      return signedUrl;
    } catch (error) {
      console.error("Error generating signed URL:", error);
      return null;
    }
  };

  async function downlaodFiles() {
    setENconsolidatedAudited2021(
      await getSignedUrlForFile(
        "financial_reports/2021/EN_Rigsave_Spa_consolidated_IFRS_compliant_2021.pdf"
      )
    );
    setITconsolidatedAudited2021(
      await getSignedUrlForFile(
        "financial_reports/2021/Rigsave-rel.-di-revisione-e-bil.-cons.-2021_RC134842021BD4450.pdf"
      )
    );
    setENitalianGAAPAudited2021(
      await getSignedUrlForFile(
        "financial_reports/2021/EN_bilancio-separato-rigsave-spa-revisionato-al-31-12-2021_def.pdf"
      )
    );
    setITitalianGAAPAudited2021(
      await getSignedUrlForFile(
        "financial_reports/2021/bilancio-separato-rigsave-spa-revisionato-al-31-12-2021.pdf"
      )
    );
    setENconsolidatedAudited2022(
      await getSignedUrlForFile(
        "financial_reports/2022/rigsave-spa-audited-consolidated-fs-2022.pdf"
      )
    );
    setENitalianGAAPAudited2022(
      await getSignedUrlForFile(
        "financial_reports/2022/EN_bilancio-separato-Rigsave-SpA-revisionato-al-31-12-2022_REV_def.pdf"
      )
    );
    setITitalianGAAPAudited2022(
      await getSignedUrlForFile(
        "financial_reports/2022/bilancio-separato-Rigsave-SpA-revisionato-31-12-2022.pdf"
      )
    );
    setITconsolidatedAudited2022(
      await getSignedUrlForFile(
        "financial_reports/2022/Rigsave_relazione-revisore-bilancio-consolidato-31.12.2022_ITA-version.pdf"
      )
    );
    setENHalfYearReport(
      await getSignedUrlForFile(
        "financial_reports/2023/Rigsave-spa-financial-statement-as-at-30-06-2023.pdf"
      )
    );
    setITHalfYearReport(
      await getSignedUrlForFile(
        "financial_reports/2023/Bilancio-semestrale-al-30-06-2023.xbrl_.pdf"
      )
    );
    setITConsolidatedAudited2023(
      await getSignedUrlForFile(
        "financial_reports/2023/IFSR_consolidated_audited_ITA.pdf"
      )
    );
    setENConsolidatedAudited2023(
      await getSignedUrlForFile(
        "financial_reports/2023/IFSR_consolidated_audited_EN.pdf"
      )
    );
    setITItalianGAAPAudited2023(
      await getSignedUrlForFile(
        "financial_reports/2023/bilancio_separato_rigsavespa_revisionato_31_12_2023.pdf"
      )
    );
    setENHalfYearConsolidated2024(
      await getSignedUrlForFile(
        "financial_reports/2024/2024_EN_IFRS_consolidated_half_year_report.pdf"
      )
    );

    /* await getUrl({
        key: "financial_reports/2024/bilancio_separato_rigsavespa_revisionato_31_12_2023.pdf__English__2024-04-29T17_42_12.pdf",
      }).then((result) => {
        setITconsolidatedAudited2024(result.url.toString());
      }); */
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]).then((responses) => {
      const tempArrayResults = [
        {
          name: `2024 EN IFRS consolidated half year report`,
          url: ENHalfYearConsolidated2024,
        },
        {
          name: `IT IFRS consolidated audited 2023`,
          url: ITConsolidatedAudited2023,
        },
        {
          name: `EN IFRS consolidated audited 2023`,
          url: ENConsolidatedAudited2023,
        },
        {
          name: `EN half year report 2023`,
          url: ENHalfYearReport,
        },
        {
          name: `IT half year report 2023`,
          url: ITHalfYearReport,
        },
        {
          name: `IT Italian GAAP Audited 2023`,
          url: ITItalianGAAPAudited2023,
        },
        {
          name: `EN consolidated audited 2022`,
          url: ENconsolidatedAudited2022,
        },
        {
          name: `IT consolidated audited 2022`,
          url: ITconsolidatedAudited2022,
        },
        {
          name: `EN italian GAAP audited 2022`,
          url: ENitalianGAAPAudited2022,
        },
        {
          name: `IT italian GAAP audited 2022`,
          url: ITitalianGAAPAudited2022,
        },
        {
          name: `EN consolidated audited 2021`,
          url: ENconsolidatedAudited2021,
        },
        {
          name: `IT consolidated audited 2021`,
          url: ITconsolidatedAudited2021,
        },
        {
          name: `EN italian GAAP audited 2021`,
          url: ENitalianGAAPAudited2021,
        },
        {
          name: `IT italian GAAP audited 2021`,
          url: ITitalianGAAPAudited2021,
        },
      ];
      setFilteredData(tempArrayResults);
      setJsonObject(tempArrayResults);
    });
  }, [
    ENConsolidatedAudited2023,
    ENHalfYearConsolidated2024,
    ENHalfYearReport,
    ENconsolidatedAudited2021,
    ENconsolidatedAudited2022,
    ENitalianGAAPAudited2021,
    ENitalianGAAPAudited2022,
    ITConsolidatedAudited2023,
    ITHalfYearReport,
    ITItalianGAAPAudited2023,
    ITconsolidatedAudited2021,
    ITconsolidatedAudited2022,
    ITitalianGAAPAudited2021,
    ITitalianGAAPAudited2022,
    downlaodFiles,
  ]);
  return (
    <>
      <div className="container wrapper-documents pt-5 mt-5 mb-5 pb-5">
        <div className="internal-wrap pt-5 mt-5 pb-5 mb-5">
          <div className="rigsave-custom-title text-center pb-5">
            {t("main.financialReport.title")}
          </div>
          <div className="text-center pb-5 mb-4">
            <input
              type="text"
              className="input-search"
              onChange={handleInputChange}
              value={searchValue}
            />
          </div>
          {filteredData ? (
            <div className="results-wrapper">
              {filteredData.map((pdf, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-6 card-documents px-5 pt-4 pb-4 mx-5 mb-5"
                  >
                    <div className="title-documents">{pdf.name}</div>
                    <Link
                      to={pdf.url}
                      className="button-contact final-a list-inline d-block text-center"
                      target="_blank"
                    >
                      Report
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="container justify-content-center text-center">
              <RotatingLines
                strokeColor="#6b97cf"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FinancialReports;
