import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Link } from "react-router-dom";

import micheleB from "../assets/Michele_Finale.png";
import salvatoreG from "../assets/Salvatore_Finale.png";
import giovanniG from "../assets/Giovanni_Finale.png";
import andreaP from "../assets/polzot.jpg";
import rigsaveGroupImg from "../assets/photos/group_description.png";

import "../css/Presentations.css";

const aws_region = process.env.REACT_APP_AWS_REGION;

const cred = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const s3Client = new S3Client({
  region: aws_region,
  credentials: cred,
});

function Presentations() {
  const { t } = useTranslation();

  const [pdfCompanyData, setPdfCompanyData] = useState("");
  const [pdfInfoMemorandum, setPdfInfoMemorandum] = useState("");

  const bucket_name = process.env.REACT_APP_S3_BUCKET_NAME;

  const getSignedUrlForFile = async (key) => {
    const command = new GetObjectCommand({
      Bucket: bucket_name,
      Key: key,
    });

    try {
      const signedUrl = await getSignedUrl(s3Client, command);
      return signedUrl;
    } catch (error) {
      console.error("Error generating signed URL:", error);
      return null;
    }
  };

  async function downlaodFiles() {
    setPdfCompanyData(
      await getSignedUrlForFile("generic/Company_Data-RIGSAVE-SPA.pdf")
    );
    setPdfInfoMemorandum(
      await getSignedUrlForFile("generic/infomemo-Rigsave-SpA-eng.pdf")
    );
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]);
  }, []);

  return (
    <>
      <div className="presentations mt-5 mb-5 pb-5">
        <div className="container pt-5 mt-5">
          <div className="rigsave-custom-title text-center pb-4">
            RIGSAVE GROUP
          </div>
          <img
            src={rigsaveGroupImg}
            width="100%"
            className="rigsave-group-img"
            alt="Rigsave Group description"
          />
          <div className="documents-wrapper">
            <div className="rigsave-custom-title text-center pb-4">
              {t("main.presentations.documents")}
            </div>
            <div className="group-detail d-lg-flex justify-content-center pt-5 mt-5 mb-5 pb-5">
              <div className="border-container col-lg-5 mx-5">
                <div className="title-documents pb-3">
                  {t("main.presentations.companyData")}
                </div>
                <div className="font-aboutUs pb-3">
                  {t("main.presentations.companyDataText")}
                </div>
                <Link
                  to={pdfCompanyData}
                  className="list-inline d-block text-center link-document"
                  target="_blank"
                >
                  {t("main.home.read")}
                </Link>
              </div>
              <div className="border-container col-lg-5 mx-5">
                <div className="title-documents pb-3">
                  {t("main.presentations.informationMemorandum")}
                </div>
                <div className="font-aboutUs pb-3">
                  {t("main.presentations.informationMemorandumText")}
                </div>
                <Link
                  to={pdfInfoMemorandum}
                  className="list-inline d-block text-center link-document"
                  target="_blank"
                >
                  {t("main.home.read")}
                </Link>
              </div>
            </div>
            <div className="governance-container px-5 pt-5 pb-5">
              <div className="text-center title pb-4">
                {t("main.presentations.keyPeople")}
              </div>
              <div className="d-lg-flex row-governance directors justify-content-center pt-4">
                <div className="col-lg-3">
                  <div className="d-flex">
                    <div className="card-gov">
                      <img src={giovanniG} alt="Salvatore Gervasi" />
                      <div className="bold-name">Giovanni Gervasi</div>
                      <div>Portfolio Manager</div>
                      <Link
                        to={
                          "https://www.linkedin.com/in/giovanni-gervasi-cefa-ba354a109/"
                        }
                        target="_blank"
                      >
                        Linkedin
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="d-flex">
                    <div className="card-gov">
                      <img src={micheleB} alt="Michele Basilicata" />
                      <div className="bold-name">Michele Basilicata</div>
                      <div>Executive director</div>
                      <Link
                        to={
                          "https://www.linkedin.com/in/michele-basilicata-a3b5658b/"
                        }
                        target="_blank"
                      >
                        Linkedin
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="d-flex">
                    <div className="card-gov">
                      <img src={salvatoreG} alt="Salvatore Gervasi" />
                      <div className="bold-name">Salvatore Gervasi</div>
                      <div>Executive director</div>
                      <Link
                        to={
                          "https://www.linkedin.com/in/salvatore-gervasi-443404112/"
                        }
                        target="_blank"
                      >
                        Linkedin
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="d-flex">
                    <div className="card-gov">
                      <img src={andreaP} alt="Andrea Polzot" />
                      <div className="bold-name">Andrea Polzot</div>
                      <div>Chief Financial Officier</div>
                      <Link
                        to={"https://www.linkedin.com/in/andreapolzot/"}
                        target="_blank"
                      >
                        Linkedin
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex button-wrapper justify-content-center pt-5 mt-4">
                <Link
                  to={"/contact-us"}
                  className="button-contact link-document list-inline d-block text-center"
                >
                  {t("main.aboutUs.contactUs")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Presentations;
